import React, { PureComponent, Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import back from "../../../assets/icons/header/back_icon.svg";
import cart from "../../../assets/icons/redeem/cart.svg";
import IconButton from "@material-ui/core/IconButton";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import Toolbar from "@material-ui/core/Toolbar";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const styles = (theme) => {
  return {
    toolbarMargin: {
      minHeight: "86px",
      [theme.breakpoints.down("641")]: {
        minHeight: "82px",
      },
      [theme.breakpoints.down("481")]: {
        minHeight: "64px",
      },
      [theme.breakpoints.down("421")]: {
        minHeight: "54px",
      },
      [theme.breakpoints.down("381")]: {
        minHeight: "50px",
      },
      [theme.breakpoints.down("361")]: {
        minHeight: "48px",
      },
      [theme.breakpoints.down("321")]: {
        minHeight: "43px",
      },
    },
    appbar: {
      backgroundColor: theme.palette.primary.main,
    },
    backBtn: {
      width: "19px",
      height: "35px",
      "--size ": "70px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      fontSize: "6px",
      [theme.breakpoints.down("641")]: {
        width: "17px",
        height: "30px",
      },
      [theme.breakpoints.down("481")]: {
        width: "12px",
        height: "22px",
      },
      [theme.breakpoints.down("381")]: {
        width: "9px",
        height: "16px",
      },
      [theme.breakpoints.down("321")]: {
        width: "8px",
        height: "15px",
      },
    },
    cartbtn: {
      width: "33px",
      height: "30px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      [theme.breakpoints.down("481")]: {
        width: "25px",
        height: "23px",
      },
      [theme.breakpoints.down("381")]: {
        width: "19px",
        height: "17px",
      },
      [theme.breakpoints.down("321")]: {
        width: "17px",
        height: "15px",
      },
    },
    mainHeader: {
      color: theme.palette.typography.texts.headerTextColor,
      fontSize: "20px",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
    },
    toolbar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "11px 30px 11px 30px",
      [theme.breakpoints.down("641")]: {
        padding: "9px 26px 9px 26px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "7px 20px 7px 20px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0px 15px 0px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0px 13px 0px 13px",
      },
    },
    countClass: {
      color: theme.palette.primary.main,
      position: "absolute",
      border: "1px solid #ffffff",
      fontSize: "1.20rem",
      fontWeight: "600",
      borderRadius: "50%",
      "-webkit-border-top-left-radius": "50%",
      "-webkit-border-top-right-radius": "50%",
      "-webkit-border-bottom-right-radius": "50%",
      "-webkit-border-bottom-left-radius": "50%",
      "-moz-border-radius-topleft": "50%",
      "-moz-border-radius-topright": "50%",
      "-moz-border-radius-bottomright": "50%",
      "-moz-border-radius-bottomleft": "50%",
      borderTopLeftRadius: "50%",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
      borderBottomLeftRadius: "50%",
      "-webkit-border-radius": "50%",
      "-moz-border-radius": "50%",
      "-khtml-border-radius": "50%",
      backgroundColor: theme.palette.cards.backgroundColor,
      transform: "translate(10px, -11px)",
      height: "24px",
      width: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        height: "22px",
        width: "22px",
      },
      [theme.breakpoints.down(641)]: {
        fontSize: "12.5px",
        height: "18px",
        width: "18px",
        transform: "translate(9px, -10px)",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "10.5px",
        height: "14px",
        width: "14px",
        transform: "translate(7px, -8px)",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "10.2px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "9.9px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "9.5px",
        height: "12px",
        width: "13px",
        transform: "translate(6px, -7px)",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "9px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        height: "12px",
        width: "12px",
        transform: "translate(5px, -6px)",
      },
    },
    emptyDiv: {
      marginRight: "1.7rem",
    },
    p0: {
      padding: 0,
    },
    pl15: {
      padding: "20px 0.5rem 20px 0",
    },

    backBtnIconCss: {
      backgroundColor: theme.palette.header.backIconColor,
      " -webkit-mask-image": `url(..${back})`,
      "mask-image": `url(..${back})`,
      mask: `url(..${back}) no-repeat center / contain`,
      "-webkit-mask": `url(..${back}) no-repeat center / contain`,
    },
    cartIconCss: {
      backgroundColor: theme.palette.dashBoardheadText.color,
      " -webkit-mask-image": `url(..${cart})`,
      "mask-image": `url(..${cart})`,
      mask: `url(..${cart}) no-repeat center / contain`,
      "-webkit-mask": `url(..${cart}) no-repeat center / contain`,
    },
    regular: {
      minHeight: "86px",
      padding: "11px 30px 11px 30px",
      [theme.breakpoints.down("641")]: {
        padding: "9px 26px 9px 26px",
        minHeight: "82px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "7px 20px 7px 20px",
        minHeight: "64px",
      },
      [theme.breakpoints.down("421")]: {
        minHeight: "54px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0px 15px 0px 15px",
        minHeight: "50px",
      },
      [theme.breakpoints.down("361")]: {
        minHeight: "48px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0px 13px 0px 13px",
        minHeight: "43px",
      },
    },
  };
};

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popoverAnchorEl: null,
    };
  }

  render() {
    const { classes, history } = this.props;
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || typeof safari !== "undefined");

    return (
      <Fragment>
        <ElevationScroll>
          <AppBar position="fixed" className={classes.appbar}>
            <Toolbar
              disableGutters
              className={classes.toolbar}
              classes={{ regular: classes.regular }}
            >
              <IconButton
                onClick={() => {
                  this.props.history.goBack();
                }}
                className={classes.p0}
              >
                <span
                  className={classes.backBtnIconCss + " " + classes.backBtn}
                />
              </IconButton>
              <Typography variant="h3" className={classes.mainHeader}>
                My Rewards
              </Typography>
              <div></div>
            </Toolbar>
            {/* <AlertMessage /> */}
          </AppBar>
        </ElevationScroll>
        <div className={classes.toolbarMargin} />
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Header));

Header.defaultProps = {
  isSubHeader: false,
};
